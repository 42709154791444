/*
 * departure/index view
 */

.grouped-departures-section {
  padding-top: $padding-large;
  padding-bottom: $padding-large;

  &-table {
    width: 100%;
    max-width: 500px;
    margin: 0 auto $margin-large;
    border-collapse: collapse;

    thead {
      margin-top: -4px;
    }
  }

  &-caption {
    max-width: 468px; /* 500px - 2 * margin */
    margin-right: auto;
    margin-bottom: $margin-default;
    margin-left: auto;
    padding: $padding-small $padding-default;
    font-family: $heading-font;
    font-size: 1.5rem;
    line-height: 2;
    text-align: left;
    background-color: $grey-darkest;
    color: white;
  }

  &-header {
    display: none;
  }

  &-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: $margin-default;
    margin-left: $margin-default;
    border: 1px solid $grey-lighter;
    text-align: center;
    background-color: white;

    &:not(:last-of-type) {
      margin-bottom: $margin-default;
    }

    > * {
      flex: 0 1 100%;
      padding: 0;
      border-bottom: 1px solid $grey-lighter;
      /* set to the same height as form elements */
      height: $form-element-height;
      line-height: 3;
    }
  }

  &-item-title {
    font-weight: bold;
  }

  &-item-period {
    flex-basis: 100%;
    
    white-space: nowrap;
    padding: 0 4px;
  }

  &-item-price {
    flex-basis: 50%;
    padding: 0 4px;
    white-space: nowrap;
  }

  &-item-language {
    flex-basis: 50%;
    border-right: 1px solid $grey-lighter;
    padding: 0 4px;
  }

  &-item-status {
    flex-basis: 100%;
  }

  &-item-book {
      order: 9;
  }

  &-item-anchor-book {
    display: block;
    padding: 0 1em;
    border-radius: 0;
    box-shadow: none;
    text-transform: uppercase;
  }

  @media (min-width: $media-m) {
    padding: 64px $padding-default 128px;

    &-table {
      @include container();
      margin-bottom: $margin-large;
      /* add space between table rows */
      border-collapse: separate;
      border-spacing: 0 4px;
    }

    &-caption {
      max-width: none;
      margin-bottom: -4px;
    }

    &-header {
      display: table-header-group;
    }

    &-header-th {
      height: $form-element-height;
      /*padding-left: $padding-default;*/
      line-height: 3;
      text-align: center;
      text-transform: uppercase;
      color: $blue-light;
      font-weight: normal;

      &.has-text-left {
        text-align: left;
        padding-left: $padding-default;
      }
    }

    &-item {
      display: table-row;

      > * {
        border-top: 1px solid $grey-lighter;

        &.has-text-left {
          padding-left: $padding-default;
          text-align: left;
        }

        &:first-child {
          border-left: 1px solid $grey-lighter;
        }
      }
    }

    &-item-title {
      width: 46%;
    }

    &-item-period {
      width: 20%;
    }

    &-item-language {
      border-right: 0;
    }

    &-item-price {
      width: 10%;
    }

    &-item-book {
      width: 24%;
    }
  }
}

.tibetan-guide .grouped-departures-section {
  &-header-th {
    color: $blue-tg-light;
  }
}
