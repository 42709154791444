@mixin container() {
  max-width: $container-width;
  margin-right: auto;
  margin-left: auto;
}

@mixin sr-only() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@mixin clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


