.section {
    margin-top: 48px;

    &__title {
        margin-top: 0;
        font-size: 1.5rem;
        border-top: 1px solid $blue-light;
        line-height: 1.2;

        @media (min-width: $media-m) {
            position: relative; /* for absolute position of &:before */
            border-top: 0;
        }

        &:before {
            content: '';
            display: block;
            width: 1.5rem;
            height: 1rem;
            background-image: url($image-diamond-green);
            background-size: contain;
            background-repeat: no-repeat no-repeat;
            background-position: left;
            transform: translateY(-50%);
            background-color: $grey-lightest;

            @media (min-width: $media-m) {
                width: 100%;
                height: 1px;
                position: absolute;
                top: 50%;
                background-color: $blue-light;
            }
        }
    }

    &__title-text {
        @media (min-width: $media-m) {
            display: inline-block;
            position: relative;
            z-index: 1; /* show on top of 1px border */
            padding-right: 48px;
            background-image: url($image-diamond-green);
            background-color: $grey-lightest;
            background-repeat: no-repeat no-repeat;
            background-position: center right 20px;
        }
    }
}

.tibetan-guide .section {
    &__title {
        border-top-color: $blue-tg-light;

        &:before {
            background-image: url($image-diamond-green-tg);

            @media (min-width: $media-m) {
                background-color: $blue-tg-light;
            }
        }
    }

    &__title-text {
        @media (min-width: $media-m) {
            background-image: url($image-diamond-green-tg);
        }
    }
}
