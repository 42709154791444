.tours-section {
  background-color:  $grey-lightest;

  /* override core styles */
  a {
    color: white;

    &:visited,
    &:focus,
    &:hover {
      color: white;
    }
  }

  &-container {
    @include container();
    padding-top: $padding-large;

  }

  &-title {
    text-align: center;
  }

  &-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  &-item {
    position: relative;

    /* maintain aspect ratio */
    &:before {
      content: '';
      display: inline-block; /* when setting this to 'block' it doesn't stretch to the full width */
      padding-bottom: 90%;
    }
  }

  &-item-anchor {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat no-repeat;
    background-size: cover;
  }

  &-item-body {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: $padding-default;
    background-color: rgba(42,52,53,0.9);
  }

  &-item-title {
    font-family: $heading-font;
    font-size: 1.125rem;
    font-weight: bold;
  }

  &-item-price {
    position: absolute;
    top: $margin-large;
    padding: $padding-small $padding-default;
    background-color: $blue-light;
    font-size: 0.875rem;
  }

  &-item-price-value {
    display: block;
    font-size: 1rem;
    font-weight: bold;
  }

  @media (min-width: $media-xs) {

    &-item {
      max-width: calc(375px - ($margin-default * 2));
      margin: 0 auto;
      box-shadow: $box-shadow-default;
      margin-bottom: $margin-default;
    }
  }

  @media (min-width: $media-m) {

    &-container {
      padding-right: $padding-default;
      padding-left: $padding-default;
    }

    &-title {
      margin-bottom: 42px;
    }

    &-list {
      @include clearfix();
      margin-bottom: -$margin-default;
    }

    &-item {
      max-width: none;
      width: calc((100% - $margin-default) / 2);
      float: left;

      &:before {
        /* original image aspect ratio */
        padding-bottom: 55%;
      }

      &:nth-of-type(odd) {
        margin-right: $margin-default;
      }
    }
  }

  @media (min-width: $media-l) {

    &-item {
      width: calc((100% - $margin-large) / 2);
      margin-bottom: $margin-large;

      &:nth-of-type(odd) {
        margin-right: $margin-large;
      }
    }

    &-item-title {
      font-size: 1.5rem;
    }

    &-item-price-value {
      font-size: 1.125rem;
    }
  }
}

.tibetan-guide .tours-section {
  &-item-price {
    background-color: $blue-tg-light;
  }
}
