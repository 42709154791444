/*
 * site/about view
 */

.contact-section {
  padding: 48px $padding-default;

  &-subsection {
    position: relative; /* for absolute position of .contact-section-subsection-title */
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 48px;
    padding-top: $padding-large;
    border-top: 1px solid $blue-light;
  }

  &-subsection-title {
    position: absolute;
    top: 0;
    display: inline-block;
    padding-right: 48px;
    background-color: $grey-lightest;
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%278%27%20height%3D%2712%27%20viewBox%3D%270%200%208%2012%27%3E%0A%20%20%3Cpath%20fill%3D%27%2309BBB6%27%20fill-rule%3D%27evenodd%27%20d%3D%27M4%200l4%206-4%206-4-6z%27%2F%3E%0A%3C%2Fsvg%3E);
    background-repeat: no-repeat no-repeat;
    background-position: center right 20px;
    margin: 0;
    transform: translateY(-50%);
  }

  &-panel-form {
    margin-bottom: $margin-large;
  }

  &-form-input {
    display: block;
    width: 100%;
    margin-bottom: $margin-small;
  }

  &-form-label {
    display: block;
    margin-bottom: $margin-small;
  }

  &-form-textarea {
    width: 100%;
    margin-bottom: $margin-small;
  }

  &-button-submit {
    padding-right: 2em;
    padding-left: 2em;
  }

  &-follow-us {
    font-weight: bold;
  }

  &-social-list {
    list-style: none;
    padding-left: 0;
  }

  &-social-item {
    display: inline-block;
  }

  &-social-item-text {
    @include sr-only();
  }

  &-social-item-icon-twitter,
  &-social-item-icon-facebook,
  &-social-item-icon-instagram,
  &-social-item-icon-whatsapp,
  &-social-item-icon-skype{
    display: inline-block;
    height: 1.5em;
    width: 1.5em;
    margin-right: $margin-small;
    background-size: contain;
  }

  &-social-item-icon-twitter {
    /* find original in /images/twitter-blue.svg */
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2724%27%20height%3D%2724%27%20viewBox%3D%270%200%2024%2024%27%3E%0A%20%20%3Cpath%20fill%3D%27%2322918E%27%20fill-rule%3D%27evenodd%27%20d%3D%27M17.26%209.38l.01.35a7.44%207.44%200%200%201-7.49%207.48%207.45%207.45%200%200%201-4.03-1.18%205.3%205.3%200%200%200%203.9-1.09%202.64%202.64%200%200%201-2.46-1.83c.4.08.8.06%201.18-.04a2.63%202.63%200%200%201-2.1-2.58v-.04c.35.2.76.32%201.19.33a2.63%202.63%200%200%201-.82-3.51%207.46%207.46%200%200%200%205.42%202.75%202.63%202.63%200%200%201%204.5-2.4c.6-.12%201.94-.27%201.94-.27-.35.53-.72%201.66-1.24%202.03M12%200C5.38%200%200%205.38%200%2012s5.38%2012%2012%2012%2012-5.38%2012-12S18.62%200%2012%200%27%2F%3E%0A%3C%2Fsvg%3E);
  }

  &-social-item-icon-facebook {
    /* find original in /images/facebook-blue.svg */
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2724%27%20height%3D%2724%27%20viewBox%3D%270%200%2024%2024%27%3E%0A%20%20%3Cpath%20fill%3D%27%2322918E%27%20fill-rule%3D%27evenodd%27%20d%3D%27M15.6%2011.5h-2.1v7h-3v-7h-2v-2h2V8.34c0-1.1.35-2.82%202.65-2.82h2.35v2.31h-1.4c-.25%200-.6.12-.6.65V9.5h2.34l-.25%202zM12%200C5.38%200%200%205.38%200%2012s5.38%2012%2012%2012%2012-5.38%2012-12S18.62%200%2012%200z%27%2F%3E%0A%3C%2Fsvg%3E);
  }

  &-social-item-icon-instagram {
    /* find original in /images/instagram-blue.svg */
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2724%27%20height%3D%2724%27%20viewBox%3D%270%200%2024%2024%27%3E%0A%20%20%3Cg%20fill%3D%27%2322918E%27%20fill-rule%3D%27evenodd%27%3E%0A%20%20%20%20%3Cpath%20d%3D%27M8.54%209.52H8V6h1v2.88c-.17.2-.32.41-.46.64zM12%208.5a3%203%200%201%201%200%206%203%203%200%200%201%200-6zm-2-.44V6h6.17c1%200%201.83.82%201.83%201.83V9.5h-2.55A3.96%203.96%200%200%200%2010%208.06zM7%209.52H6V7.84c0-.7.4-1.32%201-1.62v3.31zM16%208h1V7h-1v1z%27%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%27M19%2016.17A2.84%202.84%200%200%201%2016.17%2019H7.83A2.84%202.84%200%200%201%205%2016.17V7.83A2.84%202.84%200%200%201%207.83%205h8.34A2.84%202.84%200%200%201%2019%207.83v8.34zM12%200C5.38%200%200%205.38%200%2012s5.38%2012%2012%2012%2012-5.38%2012-12S18.62%200%2012%200z%27%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E);
  }

  &-social-item-icon-whatsapp {
    background-image: url("/images/whatsapp.svg");
  }

    &-social-item-icon-skype {
      background-image: url("/images/skype.svg");

  }

  &-phonenumbers-title {
    font-weight: bold;
  }

  &-phonenumbers-list {
    padding-left: 20px;
  }

  &-phonenumbers-item {
    margin-bottom: $margin-small;
  }

  &-opening-hours {
    margin-bottom: $margin-default;
    font-weight: bold;
  }

  &-opening-hours-season {
    font-style: italic;
  }

  &-opening-hours-season-value {
    margin-bottom: $margin-default;
  }

  &-panel-details {
    margin-right: -$margin-default;
    margin-left: -$margin-default;
    padding: $padding-large $padding-default;
    background-color: white;
    box-shadow: $box-shadow-default;
  }

  &-panel-left {

    p:first-of-type {
      margin-top: 0;
    }
  }

  &-image {
    max-width: 100%;
    margin-bottom: $margin-default;
  }

  @media (min-width: $media-m) {
    padding-top: 100px;

    &-subsection {
      @include container();
      @include clearfix();
    }

    &-panel-left {
      width: 50%;
      float: left;
    }

    &-panel-right {
      width: 50%;
      float: right;
      padding-left: $padding-large;
    }

    &-panel-details {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.tibetan-guide .contact-section {
  &-subsection {
    border-top-color: $blue-tg-light;
  }
}
