.related-topics {
    list-style: none;
    padding-left: 0;

    @media (min-width: $media-m) {
        @include clearfix();
    }
}

.related-topics__item {
    max-width: 400px;
    margin-bottom: $margin-default;

    @media (min-width: $media-m) {
        width: calc((100% - $margin-default) / 2);
        max-width: none;
        float: left;

        &:nth-of-type(odd) {
            margin-right: $margin-default;
        }
    }
}

.related-topics__anchor {
    height: 3rem;
    display: block; /* fallback */
    display: flex;
    align-items: center;
    padding-right: $padding-default;
    padding-left: 4rem;
    font-family: $heading-font;
    font-size: 1.125rem;
    line-height: 1.1;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 3rem 3rem;
    background-color: white;
    box-shadow: $box-shadow-default;
    color: $body-font-color;

    @media (min-width: $media-m) {
        font-size: 1.25rem;
    }

    &:visited {
        color: $body-font-color;
    }

    &:hover,
    &:focus {
        background-color: $grey-lightest;
        color: $grey-darkest;
    }
}
