.flash {
    margin: 0 auto $margin-large auto;
    padding: $padding-default;
    background-color: white;
    border: 1px solid $grey-lighter;
    font-weight: bold;
    text-align: center;
    color: #69b017;
}

@keyframes flash-warning {  
    from { background: rgba(204, 17, 92, .1); }
    to   { background: default; }
} 

.flash--warning {
    color: #cc115c;

    &.flash--pulse {
        animation-name: flash-warning;
        animation-duration: 1.5s;
    }
}
