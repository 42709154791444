.field-content {
    :first-child {
        margin-top: 0;
    }

    > h3, > h4, > h5, > h6 {
        font-family: $body-font;
        font-size: 1.125rem;
        color: $blue-light;
        margin: 0;
    }

    ul {
        padding-left: 20px;
    }
}

.tibetan-guide .field-content {
  > h3, > h4, > h5, > h6 {
    color: $blue-tg-light;
  }
}
