.info-pill {
    &__title {
        margin-top: 0;
        padding: $padding-default;
        font-family: $body-font;
        font-size: 1rem;
        line-height: 1;
        color: white;
        background-color: $blue-light;
    }
}

.tibetan-guide .info-pill {
    &__title {
        background-color: $blue-tg-light;
    }
}
