/**
 * Remove the border on images inside links in IE 10-.
 */

img {
  border-style: none;
}

.lowercase-modifier{
  text-transform: lowercase!important;
}