.payment-options, .payment-ok {
    text-align: center;
}

.payment-options__bank-transfer {
    margin-top: $margin-default;
    padding-top: $padding-default;
    border-top: 1px solid #e4e6eb;
    font-size: .85em;   
}

.payment-ok {
    font-size: 1.15rem;
}

.paypal {}

.paypal__error {
    padding-top: $padding-default;
    color: #c00;
}
