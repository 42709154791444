/*
 * site/login view
 */

.login {
  max-width: 375px;
  margin: 0 auto $margin-large;
  padding: $padding-default;

  &-title {
    color: $blue-darker;
    text-align: center;
  }

  &-fieldset {
    margin-bottom: $margin-default;
  }

  &-label {
    display: block;
    margin-bottom: $margin-small;
  }

  &-input {
    width: 100%;
  }

  &-error {
    color: $red;
  }

  &-submit {
    width: 100%;
  }
}

.tibetan-guide .login {
  &-title {
    color: $blue-tg-darker;
  }
}
