/*
 * topic/index view
 */

.topics-section {

  &-container {
    @include container();
    padding: $padding-large $padding-default;
  }

  &-title {
    margin-bottom: 42px;
    text-align: center;
  }

  &-list {
    list-style: none;
    padding-left: 0;
    counter-reset: topic-counter;
    margin: $margin-default -$margin-default;
  }

  &-item {
    max-width: calc(375px - ($margin-default * 2));
    margin: 0 auto $margin-default;
  }

  &-item-anchor {
    display: block;
    position: relative;
    background-size: cover;
    background-position: center center;
    box-shadow: $box-shadow-default;

    /* 1:1 aspect ratio */
    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    /* overlay with opacity */
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
    }
  }

  &-item-text {
    display: block;
    min-height: 88px;
    position: absolute;
    right: $margin-large;
    bottom: $margin-large;
    left: $margin-large;
    padding-top: $padding-default;
    color: white;
    font-family: $heading-font;
    font-size: 1.5rem;
    text-align: center;
    z-index: 1;

    /* horizontal lines (left and right) */
    &:before,
    &:after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      top: 0;
      width: calc((100% - 2em) / 2);
      background-color: white;
    }

    /* left */
    &:before {
      left: 0;
    }

    /* right */
    &:after {
      right: 0;
    }
  }

  &-item-title {

    &:before {
      content: counter(topic-counter);
      counter-increment: topic-counter;
      position: absolute;
      top: -0.75em;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (min-width: 600px) {

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &-item {
      max-width: none;
      width: 50%;
      flex: 0 1 auto;
      padding: 0px $padding-default $padding-default;
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (min-width: $media-m) {

    &-item {
      width: 33.333%;
    }

    &-item-anchor {
      /* set original aspect ratio of image */
      &:before {
        padding-bottom: 150%;
      }
    }
  }

  @media (min-width: $media-l) {

    &-item {
      width: 25%;
    }
  }
}
