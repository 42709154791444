/*
 * tour/detail view
 */

/*
 * destination-summary
 */

.destination-summary {
  margin-bottom: $margin-large;
  padding: $padding-default;
  background-color:  $grey-lightest;

  &-container {
    @include container();
  }

  &-img-map {
    display: block;
    max-width: 100%;
    margin: 0 auto $margin-large;
    border: 1px solid $grey;
  }

  &-title {
    font-size: 1rem;
    color: $grey-dark;
  }

  &-text {
    max-width: 900px;
    margin-right: auto;
    margin-bottom: $margin-large;
    margin-left: auto;
    font-style: italic;
    text-align: center;
  }

  &-details {
    margin-right: auto;
    margin-left: auto;
    text-transform: uppercase;
  }

  &-card {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  }

  &-price {
    padding: $padding-default;
    background-color: white;
  }

  &-price-from {
    color: $blue;
  }

  &-price-value {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &-separator {
    background-color: #fff;
    border-top: 1px solid #d3d2d0;
    border-bottom: 1px solid #d3d2d0;
    height: 5px;
  }

  &-definition-list {
    @include clearfix();
    margin-top: 0;
    padding: $padding-default;
    background-color: white;
  }

  &-definition-term {
    float: left;
    width: 60%;
    color: $blue;
  }

  &-definition-data {
    float: left;
    width: 40%;
    margin-left: 0;
    font-weight: bold;
  }

  &-definition-term, &-definition-data {
    padding: 2px 0;
  }

  @media (min-width: $media-l) {
    padding-bottom: $padding-large;

    &-map-container {
      @include clearfix();
    }

    &-img-map {
      float: left;
      width: calc(70% - $margin-large);
      margin-right: $margin-large;
    }

    &-details {
      float: right;
      width: 30%;
    }

    &-title {
      margin-top: 0;
    }
  }
}

.tibetan-guide .destination-summary {
  &-price-from {
    color: $blue-tg;
  }

  &-definition-term {
    color: $blue-tg;
  }
}

/*
 * carousel
 */

.carousel {
  position: relative; /* for absolute position of the buttons */

  &.is-fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 12; /* show on top of site navigation */
    background-color: rgba(0, 0, 0, 0.9);
    transition: background-color 0.3s ease-out;

    .carousel-list {
      padding-left: 0;
      /* vertically center */
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .carousel-item {
      margin-right: 0;
    }

    .carousel-img {
      width: 100%;
    }

    .carousel-button-close {
      display: block;
    }
  }

  &-list {
    list-style: none;
    padding-left: 3%; /* make some space to show a tiny bit of the previous images (positioned outside the viewport) */
    white-space: nowrap;  /* place items on 1 line */
    overflow-x: hidden; /* hide images positioned outside .carousel-list */
    font-size: 0; /* clear whitespace around .carousel-item */
  }

  &-item {
    display: inline-block;
    margin-right: $margin-small;
    transform: translateX(-200%); /* by default, position each item 200% to the left */

    &.is-animating-left {
      transition: transform 0.2s ease-out;
      transform: translateX(-300%); /* default - 100% */
    }

    &.is-animating-right {
      transition: transform 0.2s ease-out;
      transform: translateX(-100%); /* default + 100% */
    }

    &.is-animating-initial {
      /* animate back to initial state */
      transition: transform 0.2s ease-out;
    }
  }

}

.slick-carousel{
  &-item{
    height: 200px;
    padding-right: 0px;
    img{
      height: 200px;
    }
  }

  button {
    position: absolute;
    color: white;
    background-color: black; /* fallback */
    background-color: rgba(0, 0, 0, 0.75);
    cursor: pointer;
    z-index: 1;
    outline: none;

    &.slick-prev,
    &.slick-next {
      width: 3rem;
      height: 3rem;
      /* vertically center */
      top: 0;
      bottom: 0;
      padding: 0;
      margin: auto;
      /* end vertically center */
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 8px solid transparent; /* add padding around background-image */
      font-size: 0; /* hide text */
    }

    &.slick-prev {
      background-image: url(/assets/images/chevron-circle-green-left.svg);
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    &.slick-next {
      right: 0;
      background-image: url(/assets/images/chevron-circle-green-right.svg);
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

  }

  &-button-close {
    display: none;
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    padding: $padding-default;
    font-size: 1rem;
    border: 2px solid $blue-light;
    border-bottom-right-radius: $border-radius-default;
    border-bottom-left-radius: $border-radius-default;
    text-transform: uppercase;

    &:before {
      content: '×';
      margin-right: $margin-small;
    }

  }

  @media (min-width: $media-s) {

    &-item{
      height: 300px;
      padding-right: 8px;
      img{
        height: 300px;
      }
    }

    .slick-prev,
    .slick-next {
      width: 5rem;
      height: 5rem;
      border-width: 16px;
    }

    &-button-close {
      font-size: 1.25rem;
    }
  }


  @media (min-width: $media-m) {

    &-item{
      height: 400px;
      img{
        height: 400px;
      }
    }
  }
}

.tibetan-guide .slick-carousel {
  button {
    &.slick-prev {
      background-image: url(/assets/images/chevron-circle-green-left-tg.svg);
    }

    &.slick-next {
      right: 0;
      background-image: url(/assets/images/chevron-circle-green-right-tg.svg);
    }
  }

  &-button-close {
    border-color: $blue-tg-light;
  }
}

/*
 * departures-section
 */

.departures-section {
  padding-top: $padding-large;
  padding-bottom: $padding-large;

  &-title {
    text-align: center;
  }

  &-table {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border-collapse: collapse;
  }

  &-caption {
    max-width: 468px; /* 500px - 2 * margin */
    margin-right: auto;
    margin-bottom: $margin-default;
    margin-left: auto;
    padding-left: $padding-default;
    padding-right: $padding-default;
    font-family: $heading-font;
    font-size: 1.5rem;
    line-height: 2;
    text-align: left;
    background-color: $grey-darkest;
    color: white;
  }

  &-header {
    display: none;
  }

  &-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: $margin-default;
    margin-left: $margin-default;
    border: 1px solid $grey-lighter;
    text-align: center;
    background-color: white;

    &:not(:last-of-type) {
      margin-bottom: $margin-default;
    }

    > * {
      flex: 0 1 100%;
      padding: 0;
      border-bottom: 1px solid $grey-lighter;
      /* set to the same height as form elements */
      height: $form-element-height;
      line-height: 3;
    }
  }

  &-item-title {
    font-weight: bold;
  }

  &-item-period {
    flex-basis: 100%;
  }

  &-item-duration {
    flex-basis: 50%;
    border-right: 1px solid $grey-lighter;
  }

  &-item-language {
    flex-basis: 50%;
  }

  &-item-price {
    flex-basis: 50%;
    border-right: 1px solid $grey-lighter;

    &--wide {
        flex-basis: 100%;
        border-right: 0;
    }
  }

  &-item-status {
    flex-basis: 100%;
    order: 6;
    color: #6eaa21;
    padding: 0 20px;
  }

  &-item-pdf {
    flex-basis: 50%;
  }

  &-item-book {
    order: 7;
  }

  &-item-status--soon {
    color: #4e90d9;
  }

  &-item-status--warning {
    color: #f7a32a;
  }

  &-item-status--error {
    color: #ff4500;
  }

  &-item-anchor-pdf {
    color: $grey-darkest;

    &:visited,
    &:focus,
    &:hover {
      color: $grey-darkest;
    }
  }

  &-anchor-pdf-label {
    @include sr-only();
  }

  &-anchor-pdf-icon {
    /* PDF icon - find original in /images/download-pdf.svg */
    &:after {
      content: '';
      display: inline-block;
      height: 1.25em;
      width: 1.25em;
      margin-left: $margin-small;
      background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2725%27%20height%3D%2725%27%20viewBox%3D%270%200%2025%2025%27%3E%0A%20%20%3Cg%20fill%3D%27none%27%20fill-rule%3D%27evenodd%27%20stroke%3D%27%23000%27%20stroke-linejoin%3D%27round%27%3E%0A%20%20%20%20%3Cpath%20d%3D%27M15.5%2018.5L18%2021l2.5-2.5%27%20stroke-linecap%3D%27round%27%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%27M18%2012a6%206%200%201%201%200%2012%206%206%200%200%201%200-12z%27%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%27M18%2015v6M4%207h4.5M4%2010h8m-8%203h6m-6%203h5m2%206H1V1h11l5%205v4%27%20stroke-linecap%3D%27round%27%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%27M12%201v5h5%27%20stroke-linecap%3D%27round%27%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E);
      background-size: contain;
      vertical-align: middle;
    }
  }

  &-item-anchor-book {
    display: block;
    padding: 0 1em;
    border-radius: 0;
    box-shadow: none;
    text-transform: uppercase;
  }

  @media (min-width: $media-m) {
    padding: 64px $padding-default $padding-large;

    &-table {
      @include container();
      /* add space between table rows */
      border-collapse: separate;
      border-spacing: 0 4px;
    }

    &-caption {
      max-width: none;
      margin-bottom: 0;
    }

    &-header {
      display: table-header-group;
      
      tr {
        background-color: #fff;
        
        > * {
          border-top: 1px solid $grey-lighter;
          border-bottom: 1px solid $grey-lighter;
        }

        :first-child {
          border-left: 1px solid $grey-lighter;
        }

        :last-child {
          border-right: 1px solid $grey-lighter;
        }
      }
    }

    &-header-th {
      height: $form-element-height;
      line-height: 3;
      text-align: center;
      text-transform: uppercase;
      color: $blue-light;
      font-weight: normal;

      &.has-text-left {
        padding-left: $padding-default;
        text-align: left;
      }
    }

    &-item {
      display: table-row;

      > * {
        border-top: 1px solid $grey-lighter;

        &.has-text-left {
          padding-left: $padding-default;
          text-align: left;
        }

        &:first-child {
          border-left: 1px solid $grey-lighter;
        }
      }
    }

    &-item-duration {
      border-right: 0;
    }

    &-item-price {
      border-right: 0;
    }

    &-item-book {
      width: 185px;
    }

    &-item-period, &-item-status {
      line-height: 1.2;
      white-space: nowrap;
    }
  }
}

.tibetan-guide .departures-section {
  &-header-th {
    color: $blue-tg-light;
  }
}

/*
 * guide-section
 */

.guide-section {
  margin-bottom: 64px;
  padding-top: $padding-large;
  padding-bottom: $padding-large;
  background: $grey-lightest;

  &-title {
    text-align: center;
  }

  &-tab-list {
    display: none;
  }

  @media (min-width: $media-m) {

    &-tab-bar {
      @include container();
      margin-bottom: 4px;
    }

    &-tab-list {
      display: table;
      width: 100%;
      margin: 0;
      padding-left: 0;
    }

    &-tab-item {
      display: table-cell;
      width: 33.33%;

      &:not(:last-of-type) {
        border-right: 3px solid $grey-lightest;
      }
    }

    &-tab-anchor {
      display: block;
      padding: 12px $padding-default;
      font-family: $heading-font;
      font-size: 1.5rem;
      color: $grey-dark;
      background-color: white;
      text-align: center;

      &.is-active {
        background-color: $blue-light;
        color: white;
      }
    }

    &-panels {
      @include container();
      background-color: white;
      padding-bottom: 180px;
      background-image: url(/assets/images/tour-pattern.svg);
      background-position: bottom 16px center;
      background-repeat: no-repeat;
    }

    &-panel {
      display: none;

      &.is-active {
        display: block;

        /* when resizing, override expandable state on nested components */
        .history-section-body,
        .itinerary-section-body {
          display: block;
        }

        /* when resizing, override expandable state on nested components */
        .inclusions-section-body {
          display: flex;
        }
      }
    }
  }
}

.tibetan-guide .guide-section {
  @media (min-width: $media-m) {
    &-panels {
      background-image: url(/assets/images/tour-pattern-tg.svg);
    }

    &-tab-anchor {
      &.is-active {
        background-color: $blue-tg-light;

        &:hover {
          color: $blue-tg-lightest;
        }
      }
    }
  }
}

/*
 * history-section
 */

.history-section {

  &-body {
    display: none;

    &.is-expanded {
      display: block;
      min-height: 110px;
    }
  }

  &-panel-text {
    padding: $padding-large;
    background-color: white;
  }

  &-title {
    margin-top: 0;
    margin-bottom: 0;
  }

  &-text {

    p {
      margin-top: 0;
    }
  }

  &-panel-itinerary {
    padding: $padding-large;
    background-color: $grey-darkest;
    color: white;
  }

  &-itinerary-list {
    list-style: none;
    padding-left: 0;
    font-family: $heading-font;
  }

  &-itinerary-item {

    &:not(:last-of-type) {
      margin-bottom: $margin-small;
    }
  }

  &-itinerary-title {
    margin-top: 0;
    color: $blue-light;
    font-weight: bold;
  }

  @media (min-width: $media-m) {
    position: relative;

    &-title {
      /* hide visually, the title will be visible inside the tab-bar instead */
      @include sr-only;
    }

    &-panel-text {
      width: 60%;
    }

    &-panel-itinerary {
      width: 40%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }

    &-itinerary-title {
      font-size: 2rem;
    }
  }
}

.tibetan-guide .history-section {
  &-itinerary-title {
    color: $blue-tg-light;
  }
}

/*
 * itinerary-section
 */

.itinerary-section {
  background-color: $grey-lightest;

  &-title {
    margin-top: 0;
    margin-bottom: 0;
  }

  &-list {
    display: none;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;

    &.is-expanded {
      display: block;
    }
  }

  &-item-title {
    font-size: 1.25rem;
    color: $body-font-color;
  }

  &-item-body {
    display: none;
    background-color: white;

    &.is-expanded {
      display: block;
    }
  }


  &-item-text {
    padding: $padding-default $padding-large;
  }

  h3, h4 {
    margin-bottom: 0;
    font-size: 1.25rem;
    color: $blue-light;
    font-weight: bold;
  }

  h1{
    font-size: 1.75rem;
  }

  h2{
    font-size: 1.5rem;
  }

  &-button-date {
    font-weight: bold;
  }

  &-button-title {
    display: none;
    font-weight: bold;
  }

  &-item-side-panel {
    position: relative;
    min-height: 500px;
    padding: $padding-default;
    background-image: url(/assets/images/itinerary-item-background.jpg);
    background-size: cover;
    background-position: center center;
    color: white;
    height:100%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,0.7);
      border-radius: inherit;
    }
  }

  &-item-side-panel-text {
    position: relative;
    z-index: 1;
    padding: $padding-default;
    h1,h2,h3{
      font-family: $heading-font;
      color: white;
      margin-top: 0;
    }

  }

  &-item-side-panel-title {
    font-family: $heading-font;
    font-size: 1.25rem;
  }

  &-item-side-panel-list {
    list-style: none;
    padding-left: 0;
  }

  @media (min-width: $media-m) {

    &-title {
      /* hide visually, the title will be visible inside the tab-bar instead */
      @include sr-only;
    }

    &-item {
      margin-bottom: 2px;
    }

    &-item-body {
      @include clearfix();
    }

    &-item-text {
      width: 60%;
      float: left;
    }

    &-item-side-panel {
      width: calc(40% - $margin-large);
      margin: $margin-default;
      float: right;
      border-radius: $margin-small;
    }

    &-item-text {
      padding-right: $padding-default;
    }

    &-button-expand {
      position: relative; /* for absolute position of &-button-date */
      background-color: white;
      color: $body-font-color;

      /* override chevron background images defined in the core styles */

      /* find original in assets/images/chevron-up-black.svg */
      &[aria-expanded="false"] {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='10' viewBox='0 0 17 10'%3E%3Cpath fill='%23000' fill-rule='nonzero' stroke='%23000' stroke-width='.5' d='M1.55 1.1a.31.31 0 1 0-.47.42l7.19 8.12c.12.15.34.15.46 0l7.2-8.12a.31.31 0 1 0-.48-.41L8.5 8.97 1.55 1.1z'/%3E%3C/svg%3E");
      }

      /* find original in assets/images/chevron-down-black.svg */
      &[aria-expanded="true"] {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='10' viewBox='0 0 17 10'%3E%3Cpath fill='%23000' fill-rule='nonzero' stroke='%23000' stroke-width='.5' d='M15.45 9.64a.31.31 0 0 0 .47-.41L8.73 1.11a.31.31 0 0 0-.46 0l-7.2 8.12a.31.31 0 1 0 .48.41L8.5 1.78l6.95 7.86z'/%3E%3C/svg%3E");
      }
    }

    &-button-date {
      display: block;
      width: 10rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      padding-left: $padding-default;
      background-color: $blue-light;
      color: white;
      line-height: 2.5;
    }

    &-button-title {
      display: block;
      float: right;
      width: calc(100% - 10rem);
      font-weight: normal;
    }
  }
}

.tibetan-guide .itinerary-section {
  h3, h4 {
    color: $blue-tg-light;
  }

  &-button-date {
    background-color: $blue-tg-light;
  }
}

/*
 * inclusions-section
 */

.inclusions-section {

  &-title {
    margin-top: 0;
    margin-bottom: 0;
    color: $blue;
  }

  &-body {
    display: none;

    &.is-expanded {
      display: block;
    }
  }

  &-panel {
    padding: $padding-default;
  }

  &-subtitle {
    color: $blue-light;
  }

  &-list {
    margin-top: 0;
    padding-left: $padding-default;
  }

  &-item {

    &:not(:last-of-type) {
      margin-bottom: $margin-small;
    }
  }

  @media (min-width: $media-s) {

    &-body {

      &.is-expanded {
        display: flex;
      }
    }

    &-panel {
      display: flex;
      flex-direction: column;
    }

    &-list {
      flex-grow: 1;
      padding-top: $padding-large;
      padding-bottom: $padding-large;
      border-bottom: 1px solid $blue;
    }

    &-subtitle {
      position: relative; /* for absolute position of &:before */
      margin-bottom: 0;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        background-color: $blue-light;
      }
    }

    &-subtitle-text {
      display: inline-block;
      position: relative; /* show on top of the horizontal line */
      padding-right: $padding-default;
      background-color: white;
    }
  }

  @media (min-width: $media-m) {

    &-title {
      /* hide visually, the title will be visible inside the tab-bar instead */
      @include sr-only;
    }

    &-panel {
      width: calc((100% - $margin-large) / 2);
    }
  }
}

.tibetan-guide .inclusions-section {
  &-title {
    color: $blue-tg;
  }

  &-subtitle {
    color: $blue-tg-light;
  }

  &-list {
    border-bottom-color: $blue-tg;
  }

  @media (min-width: $media-s) {
    &-subtitle {
      &:before {
        background-color: $blue-tg-light;
      }
    }
  }
}
