.destinations-section {

  &-container {
    @include container();
    padding-top: $padding-large;
    padding-bottom: $padding-large;
    text-align: center;
    color: white;
  }


  /* override core styles */
  a {
    color: white;

    &:visited,
    &:focus,
    &:hover {
      color: white;
    }
  }

  &-title {
    margin-bottom: 42px;
  }

  &-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    text-transform: uppercase;
  }

  &-item {
    position: relative; /* for absolute position of &-item-anchor */
    margin-bottom: $margin-default;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  &-item-anchor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $padding-default;
    background-size: cover;
    background-position: center;
    background-color: #2a918d;
    background-blend-mode: multiply;
  }

  &-item-title {
    margin-bottom: $margin-default;
    font-family: $heading-font;
    font-size: 2rem;
  }

  &-item-body {
    margin-bottom: $margin-small;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    line-height: 2;
  }

  &-item-tours {
    margin-right: $margin-small;
  }

  &-item-diamond {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: $margin-small;
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%278%27%20height%3D%2712%27%20viewBox%3D%270%200%208%2012%27%3E%3Cpath%20fill%3D%27%23FFF%27%20fill-rule%3D%27evenodd%27%20d%3D%27M4%200l4%206-4%206-4-6z%27%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    vertical-align: text-bottom;
  }

  &-item-price {
    display: block;
    font-size: 1.5rem;
  }

  &-map {
    max-width: 100%;
    width: 100%;
    margin-top: $margin-large;
  }

  @media (min-width: $media-xs) {

    &-item {
      max-width: calc(375px - ($margin-default * 2));
      margin-right: auto;
      margin-left: auto;
      box-shadow: $box-shadow-default;
    }
  }

  @media (min-width: $media-m) {

    &-container {
      padding: $padding-default $padding-default 128px;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: -$margin-large;
    }

    &-item {
      max-width: none;
      width: calc((100% - $margin-default) / 2);
      flex: 0 1 auto;
      margin: 0 0 $margin-default;
    }
  }

  @media (min-width: $media-l) {

    &-item {
      width: calc((100% - ($margin-large * 2)) / 3);
      margin-bottom: $margin-large;
    }
  }
}