.button-green,
.button-orange,
.button-blue,
[role="button"] {
  display: inline-block; /* required for styling <a> as button */
  height: $form-element-height;
  margin: 0; /* Remove the margin in Firefox and Safari. */
  padding: 0 1em;
  border: 1px solid transparent;
  border-radius: $border-radius-default;
  box-shadow: 0 2px 0 0 $blue;
  font-family: $body-font;
  font-size: 1rem;
  font-weight: normal;
  text-transform: none; /* Remove the inheritance of text transform in Firefox. */
  text-align: center;
  user-select: none;
  color: white;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  overflow: visible; /* Show the overflow in Edge. */
  /* required for styling <a> as button */
  line-height: 3; /* equal to form element height */
  text-decoration: none;

  &::-moz-focus-inner {
    /* Remove the inner border and padding in Firefox. */
    border-style: none;
    padding: 0;
  }

  &:active {
    background-image: none;
  }

  &.is-disabled, /* for anchors styled as buttons */
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.tibetan-guide {
    .button-green,
    .button-orange,
    .button-blue,
    [role="button"] {
        box-shadow: 0 2px 0 0 $blue-tg;
    }
}

.button-wide {
  padding-right: 2em;
  padding-left: 2em;
}

button.button-green {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS and Safari. */
}
/*
$grey-darkest: #2A3435;
$grey-darker: #4A4A4A;
$grey-dark: #9b9da2;
$grey: #cbced3;
$grey-lighter: #e4e6eb;
$grey-lightest: #f1f1f1;
*/
.button-disabled {
    background-color: #dad9d5;
    color: white;

    /* for <a> styled as button */
    &:visited {
      background-color: #dad9d5;
      color: white;
    }

    &:hover,
    &:focus {
      background-color: #dad9d5;
      color: white;
    }
}

.button-green {
  /* for <a> styled as button */
  &,
  &:hover,
  &:focus,
  &:visited {
    background-color: $blue-light;
    color: white;
  }

  &:hover,
  &:focus {
    background-color: $blue;
  }
}

.tibetan-guide .button-green {
  &,
  &:hover,
  &:focus,
  &:visited {
    color: #fff;
    background-color: $blue-tg-light;
  }

  &:hover,
  &:focus {
    background-color: $blue-tg;
  }
}

.button-orange {
    background-color: $orange;
    color: white;

    /* for <a> styled as button */
    &:visited {
      background-color: $orange;
      color: white;
    }

    &:hover,
    &:focus {
      background-color: $orange-dark;
      color: white;
    }
}

.button-blue {
  background-color: $ocean-blue;
  color: white;

  /* for <a> styled as button */
  &:visited {
    background-color: $ocean-blue;
    color: white;
  }

  &:hover,
  &:focus {
    background-color: $ocean-blue-dark;
    color: white;
  }
}

.button-link {
  display: inline;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  border: none;
  background: none;
  color: $blue;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $blue-darker;
  }
}

.tibetan-guide .button-link {
  color: $blue-tg;

  &:hover {
    color: $blue-tg-darker;
  }
}

/*
 * Anchors
 */

a {
  text-decoration: none;

  &,
  &:visited {
    color: $blue;
  }

  &:hover {
    color: $blue-darker;
  }
}

.tibetan-guide a {
  &,
  &:visited {
    color: $blue-tg;
  }

  &:hover {
    color: $blue-tg-darker;
  }
}

/*
 * Expandable buttons
 */

.button-expand-primary,
.button-expand-secondary {
  display: block;
  width: 100%;
  margin-bottom: 1px;
  padding: 1rem;
  border: 0;
  font-family: $heading-font;
  font-size: 1.5rem;
  text-align: left;
  font-weight: bold;

  &[aria-expanded] {
    background-repeat: no-repeat;
    background-position: right 16px center;
  }

  &[aria-expanded="true"] {
    /* find original in assets/images/chevron-down-white.svg */
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2717%27%20height%3D%2710%27%20viewBox%3D%270%200%2017%2010%27%3E%0A%20%20%3Cpath%20fill%3D%27%23fff%27%20stroke%3D%27%23fff%27%20stroke-width%3D%27.5%27%20d%3D%27M15.45%209.64a.31.31%200%200%200%20.47-.41L8.73%201.11a.31.31%200%200%200-.46%200l-7.2%208.12a.31.31%200%201%200%20.48.41L8.5%201.78l6.95%207.86z%27%2F%3E%0A%3C%2Fsvg%3E%0A);
  }

  &[aria-expanded="false"] {
    /* find original in assets/images/chevron-up-white.svg */
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2717%27%20height%3D%2710%27%20viewBox%3D%270%200%2017%2010%27%3E%0A%20%20%3Cpath%20fill%3D%27%23fff%27%20stroke%3D%27%23fff%27%20stroke-width%3D%27.5%27%20d%3D%27M1.55%201.1a.31.31%200%201%200-.47.42l7.19%208.12c.12.15.34.15.46%200l7.2-8.12a.31.31%200%201%200-.48-.41L8.5%208.97%201.55%201.1z%27%2F%3E%0A%3C%2Fsvg%3E%0A);
  }
}

.button-expand-primary {
  background-color: white;
  color: $grey-dark;

  &[aria-expanded="true"] {
    background-color: $blue-light;
    color: white;
  }
}

.tibetan-guide .button-expand-primary {
  &[aria-expanded="true"] {
    background-color: $blue-tg-light;
  }
}

.button-expand-secondary {
  background-color: $blue-darker;
  color: white;
}

.tibetan-guide .button-expand-secondary {
  background-color: $blue-tg-darker;
}
