.footer{
  padding: $padding-large $padding-default;
  background-color: $grey-darkest;
  color: white;
  text-align: center;

  /* override core styles */
  a {
    color: white;

    &:visited,
    &:focus,
    &:hover {
      color: $grey-lightest;
    }
  }

  &-container {
    @include container();
  }

  &-logo {
    width: 96px;
    height: 96px;
    margin-bottom: $margin-default;
  }

  &-logo-tg img {
      margin-top: 15px;
  }

  &-social-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: $margin-large;
  }

  &-social-item {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: $margin-default;
    }
  }

  &-social-item-email,
  &-social-item-twitter,
  &-social-item-instagram,
  &-social-item-facebook {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat no-repeat;
  }

  &-social-item-email {
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2722%27%20height%3D%2716%27%20viewBox%3D%270%200%2022%2016%27%3E%3Cpath%20fill%3D%27%23DAD9D4%27%20fill-rule%3D%27evenodd%27%20d%3D%27M11%208.86L.95.81C1.25.6%201.6.5%202%20.5h18c.4%200%20.78.12%201.1.33L11%208.86zm10.76-7.28c.15.27.24.58.24.92v11a2%202%200%200%201-2%202H2a2%202%200%200%201-2-2v-11c0-.35.1-.68.26-.96L10.7%209.89c.18.13.42.13.6%200l10.46-8.31z%27%2F%3E%3C%2Fsvg%3E);
  }

  &-social-item-twitter {
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2723%27%20height%3D%2718%27%20viewBox%3D%270%200%2023%2018%27%3E%3Cpath%20fill%3D%27%23DAD9D4%27%20fill-rule%3D%27evenodd%27%20d%3D%27M20.22%202.35c.94-.56.98-.96%201.32-2.02a9.1%209.1%200%200%201-2.89%201.1%204.54%204.54%200%200%200-7.74%204.15A12.9%2012.9%200%200%201%201.54.83a4.52%204.52%200%200%200%201.4%206.07A4.53%204.53%200%200%201%20.9%206.33v.06c0%202.2%201.57%204.04%203.65%204.45-.67.19-1.37.21-2.06.08a4.55%204.55%200%200%200%204.25%203.16A9.14%209.14%200%200%201%200%2015.96%2012.87%2012.87%200%200%200%206.97%2018c8.35%200%2012.92-6.92%2012.92-12.93v-.59c.88-.64%201.94-1.23%202.55-2.15-.81.37-1.5.38-2.22.02z%27%2F%3E%3C%2Fsvg%3E);
  }

  &-social-item-instagram {
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2722%27%20height%3D%2722%27%20viewBox%3D%270%200%2022%2022%27%3E%3Cpath%20fill%3D%27%23DAD9D4%27%20fill-rule%3D%27evenodd%27%20d%3D%27M11%205a6%206%200%201%201%200%2012%206%206%200%200%201%200-12zM3%20.05V7.5H2V.35c.31-.15.65-.25%201-.3zm2%207.37l-.05.08H4V0h1v7.42zm17%2010.43A4.16%204.16%200%200%201%2017.85%2022H4.15A4.16%204.16%200%200%201%200%2017.85V3.5c0-.95.38-1.81%201-2.44v16.79A3.16%203.16%200%200%200%204.15%2021h13.7A3.16%203.16%200%200%200%2021%2017.85V7.5h-3.95A7%207%200%200%200%206%206.12V0h12.5A3.5%203.5%200%200%201%2022%203.5v14.35zM20.5%206a.5.5%200%200%200%20.5-.5v-3a.5.5%200%200%200-.5-.5h-3a.5.5%200%200%200-.5.5v3c0%20.28.22.5.5.5h3z%27%2F%3E%3C%2Fsvg%3E);
  }

  &-social-item-facebook {
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2712%27%20height%3D%2722%27%20viewBox%3D%270%200%2012%2022%27%3E%3Cpath%20fill%3D%27%23DAD9D4%27%20fill-rule%3D%27evenodd%27%20d%3D%27M11.25%207.33H7.33V5.6c0-.82.55-1.01.93-1.01H11V.96L7.03.95c-3.6%200-4.28%202.7-4.28%204.42v1.96H0V11h2.75v11h4.58V11h3.53l.39-3.67z%27%2F%3E%3C%2Fsvg%3E);
  }

  &-social-item-text {
    @include sr-only();
  }

  &-navigation-list {
    margin-bottom: $margin-large;
    list-style: none;
    padding-left: 0;
  }

  &-navigation-item {
    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }

  &-legal-list {
    margin-bottom: $margin-large;
    list-style: none;
    padding-left: 0;
  }

  &-legal-item {
    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }

  @media (min-width: $media-s) {

    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-logo,
    &-logo-tg,
    &-navigation,
    &-legal,
    &-social-list {
      width: 50%;
    }

    &-social-list {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-navigation-list,
    &-legal-list {
      display: inline-block;
      text-align: left;
    }
  }

  @media (min-width: $media-l) {

    &-logo,
    &-logo-tg,
    &-navigation,
    &-legal,
    &-social-list {
      width: 25%;
    }

    &-logo-tg img {
      margin-top: 40px;
    }

    &-social-list {
      order: 1;
    }
  }
}
