.header {
  position: relative; /* for absolute position of .header:after */
  padding: 36px $padding-default;
  text-align: center;
  background-size: cover;
  background-position: center center;
  color: white;

  /* opacity layer on top of background image */
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7)
  }

  &.is-large {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  &.is-stacked {
    margin-top: -$navigation-height-small;
    padding-top: 108px;
    padding-bottom: 64px;
  }

  &.has-gradient {
    /* set gradient on layer on top of background image */
    &:after {
      background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
    }
  }

  &-container {
    position: relative;
    z-index: 1;
  }

  &-logo {
    width: 140px;
    margin-bottom: $margin-default;

    &--tg {
        width: 300px;
    }
  }

  &-title {
    color: white;
    text-transform: uppercase;
  }

  &-body {
    max-width: 950px;
    position: relative; /* for absolute position of &:before and &:after */
    margin-right: auto;
    margin-left: auto;
    padding: 24px $padding-default $padding-default;
    border-bottom: 1px solid white;
    font-family: $heading-font;
    /* diamond icon */
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%278%27%20height%3D%2712%27%20viewBox%3D%270%200%208%2012%27%3E%3Cpath%20fill%3D%27%23FFF%27%20fill-rule%3D%27evenodd%27%20d%3D%27M4%200l4%206-4%206-4-6z%27%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat no-repeat;
    background-position: center top;

    /* horizontal lines (left and right) */
    &:before,
    &:after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      top: 4px;
      width: calc((100% - 3em) / 2);
      background-color: white;
    }

    /* left */
    &:before {
      left: 0;
    }

    /* right */
    &:after {
      right: 0;
    }
  }

  &-slogan {
    max-width: 280px; /* prevent line wrap */
    margin: 0 auto $margin-default;
    font-family: $heading-font;
    font-size: 1.75rem;
    line-height: 1.25;
  }

  &-anchor-cta {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  &-bottom-bar {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; /* show on top of background overlay */
    line-height: 2;
    background-color: rgba(34,145,142,0.6);
    text-transform: uppercase;
  }

  @media (min-width: 530px) {

    &.is-stacked {
      margin-top: -$navigation-height-medium;
    }
  }

  @media (min-width: $media-m) {

    &.is-large {
      padding-top: 128px;
      padding-bottom: 128px;
    }

    &.is-stacked {
      margin-top: -$navigation-height-large;
      padding-top: 156px;
      padding-bottom: 128px;
    }

    &-logo {
      margin-bottom: 48px;
    }

    &-slogan {
      max-width: none;
      margin-bottom: $margin-large;
      font-size: 2rem;
    }
  }

  @media (min-width: $media-m) {

    &-title {
      font-size: 2rem;
    }

    &-body {
      font-size: 1.25rem;
    }
  }
}

.tibetan-guide .header-bottom-bar {
    background-color: rgba(43, 115, 181, 0.6);
}
