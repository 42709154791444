/*
 * site/index view
 */

.secondary-navigation {

  &-list {
    @include container();
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  &-item-anchor {
    display: block;
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    font-family: $heading-font;
    font-size: 1.125rem;
    color: white;
    text-align: center;
    text-transform: uppercase;

    &:visited,
    &:focus,
    &:hover {
      color: white;
    }

    /* maintain aspect ratio of background-image */
    &:before {
      content: '';
      display: inline-block;
      padding-bottom: 36%;
    }
  }

  &-item-anchor-travel-info {
    background-image: url(/assets/images/travel-info.jpg);
  }

  &-item-anchor-about-us {
    background-image: url(/assets/images/about-us.jpg);
  }

  &-item-anchor-label {
    /* vertically center */
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
  }

  @media (min-width: $media-m) {

    &-list {
      @include clearfix();
    }

    &-item {
      width: 50%;
      float: left;
    }

    &-item-anchor-label {
      font-size: 1.5rem;
    }
  }

  @media (min-width: $media-l) {

    &-list {
      padding-right: $padding-default;
      padding-left: $padding-default;
    }

    &-item {
      width: calc(50% - ($margin-large / 2));
      margin-bottom: $margin-large;
      box-shadow: $box-shadow-default;

      &:first-of-type {
        margin-right: $margin-large;
      }
    }
  }
}

.tibetan-guide a.secondary-navigation-item-anchor {
    color: $blue-tg-lightest;

    &:hover {
        color: $blue-tg-lighter;
    }
}
