*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  min-width: 320px;
  margin: 0;
  background-color: $grey-lightest;

  &.scroll-disabled {
    overflow: hidden;
  }
}

/* hide content on older browsers (< IE11) */
[hidden] {
  display: none !important;
}

main, .main {
  max-width: 650px;
  margin: 0 auto;
  padding-top: $padding-default;
  padding-bottom: $padding-large;

  @media (min-width: $media-m) {
    max-width: $container-width;
    padding-top: $padding-large;
    padding-bottom: 80px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.row--center {
  justify-content: center;
}

.row__column {
  flex: 100% 0 0;
  padding: 0 25px;
}

.row__column > .row {
  margin: 0 -25px;
}

.row__column--large-order-1 {
  @media (min-width: $media-m) {
    order: 1;
  }
}

.row__column--large-order-2 {
  @media (min-width: $media-m) {
    order: 2;
  }
}

.row__column--large-order-3 {
  @media (min-width: $media-m) {
    order: 3;
  }
}

.row__column--40 { flex: 40% 0 0; }
.row__column--50 { flex: 50% 0 0; }
.row__column--60 { flex: 60% 0 0; }

.debug-rect {
    width: 100%;
    height: 50px;
    background-color: #c5dde0;
    margin: 20px 0;
}

@media only screen and (max-width: $media-m) {
    .row__column--40 { flex: 100% 0 0; }
    .row__column--50 { flex: 100% 0 0; }
    .row__column--60 { flex: 100% 0 0; }
}

.desktop-only{
  @media (max-width: $media-m) {
    display: none;
  }
}

.mobile-only{
  @media (min-width: $media-m) {
    display: none;
  }
}