.anchor {
    /* offset anchor to adjust for fixed header */
    /* https://stackoverflow.com/questions/10732690/ */
    display: block;
    position: relative;
    top: calc(-$navigation-height-small - 48px);
    visibility: hidden;

    @media (min-width: 530px) {
        top: calc(-$navigation-height-medium - 48px);
    }

    @media (min-width: $media-m) {
        top: calc(-$navigation-height-large - 48px);
    }
}
