.figure {
    margin: $margin-large 0 0 0;

    @media (min-width: $media-m) {
        margin-top: 0;
    }

    &__image {
        max-width: 100%;
    }

    &__caption {
        color: $blue-light;
        font-style: italic;
        text-align: center;
    }
}

.tibetan-guide .figure {
    &__caption {
        color: $blue-tg-light;
    }
}
