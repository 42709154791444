.panel {
    padding: $padding-large $padding-default;
    background-color: white;
    box-shadow: $box-shadow-default;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }

    ul {
        padding-left: 20px;
    }

    li {
        margin-bottom: $margin-small;
    }
}

.panel--flat {
  box-shadow: none;
  border: 1px solid #e4e6eb;
}

/* ----- */


/*
 * toc (table-of-contents)
 */

.toc {

  &-panel {
    padding: 24px $padding-default $padding-large;
    background-color: white;
    box-shadow: $box-shadow-default;

    &.is-fixed {
      position: fixed;
      top: $navigation-height-small;
      left: 0;
      width: 100%;
      z-index: 12;
      transform: translateX(-100%);

      .toc-button-open {
        display: block;
        box-shadow: $box-shadow-default;
      }
    }

    &.is-opened {
      height: calc(100% - $navigation-height-small);
      overflow-y: scroll;
      transition: transform 0.2s ease-out;
      transform: translateX(0);

      .toc-button-open {
        display: none;
      }

      .toc-button-close {
        display: block;
      }
    }
  }

  &-title {
    display: inline-block; /* shrink to fit text, so that it doesn't capture background clicks */
    font-family: $heading-font;
    font-size: 1.5rem;
  }

  &-button-open,
  &-button-close {
    display: none;
    height: $form-element-height;
    width: 3rem;
    position: absolute;
    top: 8px;
    background-color: $blue-light;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 8px solid transparent; /* add padding around background image */
    font-size: 0; /* hide text */
  }

  &-button-open {
    background-image: url(/assets/images/toc-expand.svg);
    right: -3rem;
    border-top-right-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
  }

  &-button-close {
    display: none;
    background-image: url(/assets/images/toc-collapse.svg);
    right: 0;
    border-top-left-radius: $border-radius-default;
    border-bottom-left-radius: $border-radius-default;
  }

  &-list {
    padding-left: 20px;
  }

  &-item {
    margin-bottom: $margin-small;
  }

  @media (min-width: 530px) {

    &-panel {

      &.is-fixed {
        width: 400px;
        height: auto;
        top: calc($navigation-height-medium + 8px);
      }
    }

    &-button-open,
    &-button-close {
      top: 0;
    }
  }

  @media (min-width: $media-m) {

    &-panel {
      &.is-fixed {
        top: calc($navigation-height-large + 8px);
      }
    }
  }

  @media (min-width: $media-xl) {
    display: inline-block;
  }

  @media (min-width: 1266px) {

    &-panel {
      &.is-fixed {
        top: calc($navigation-height-large + 42px);
      }
    }
  }
}

.tibetan-guide .toc {
  &-button-open,
  &-button-close {
    background-color: $blue-tg-light;
  }
}
